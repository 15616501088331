import React, { useEffect } from 'react'
import './TeamSection.scss'

import img1 from '../../../../assets/images/team/1.webp'
import img2 from '../../../../assets/images/team/2.webp'
import img3 from '../../../../assets/images/team/3.webp'
import img4 from '../../../../assets/images/team/4.webp'
// import teamProfile from '../../../../assets/images/team/profile.png'

import twitterImg from '../../../../assets/images/socials/twitter.svg'

function TeamSection() {

    let observer = new IntersectionObserver(function (entries) {
        let team1 = document.getElementById('team-1')
        let team2 = document.getElementById('team-2')
        // let team3 = document.getElementById('team-3')
        let team4 = document.getElementById('team-4')

        const animate = () => {
            team1.style.top = '0px';
            team1.style.opacity = '1';
            team1.style.transform = 'scale3d(1,1,1)';

            team2.style.top = '0px';
            team2.style.opacity = '1';
            team2.style.transform = 'scale3d(1,1,1)';

            // team3.style.top = '0px';
            // team3.style.opacity = '1';
            // team3.style.transform = 'scale3d(1,1,1)';

            team4.style.top = '0px';
            team4.style.opacity = '1';
            team4.style.transform = 'scale3d(1,1,1)';

        }


        for (var i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting === true) {
                if (window.innerWidth > 669) {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {
                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()
                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
                else {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {

                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()

                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
            }
        }
    }, { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });

    useEffect(() => {
        observer.observe(document.getElementsByClassName('team-section')[0]);
    }, [])

    const openLink=(link)=>{
        window.open(link)
    }
    return (
        <div className='team-section'>
            <div className='team-title ffam-edo tc-pink fs-xls'>
                All Female Entrepreneur Led Team
            </div>
            <div className='team-sub-title ffam-edo tc-white fs-l'>
                Meet The Blockchain Babes
            </div>

            <div className='team-section-inner'>
                <div id='team-1' className='team-wrapper'>
                    {/* <img src={teamProfile} className='team-personal-profile' /> */}
                    <img src={img1} className='team-pfp' />
                    <div className='team-bottom'>
                        <p className='team-name tc-pink fs-ms ffam-lato fw-bold'>Vanessa aka BlockchainV</p>
                        <p className='team-desc tc-white fs-xs ffam-lato fw-light'>

                            Co-founder of BBW & PAC
                            <br />
                            Health Tech Entrepreneur
                            <br />
                            Blockchain Babe since 2016
                            <br />
                            “Always Laser Focused”
                        </p>
                    </div>
                    <button onClick={()=>{openLink('https://twitter.com/BlockchainV_')}} className='team-social-btn'>
                        <img src={twitterImg}/>
                    </button>
                </div>
                <div id='team-2' className='team-wrapper'>
                    {/* <img src={teamProfile} className='team-personal-profile' /> */}
                    <img src={img2} className='team-pfp' />

                    <div className='team-bottom'>
                        <p className='team-name tc-pink fs-ms ffam-lato fw-bold'>Nancy aka Fancy</p>
                        <p className='team-desc tc-white fs-xs ffam-lato fw-light'>

                            Co-founder of BBW & PAC
                            <br />
                            Business Solutions Entrepreneur
                            <br />
                            Blockchain Babe since 2016
                            <br />
                            “She’s Fancy”
                        </p>
                    </div>
                    <button onClick={()=>{openLink('https://twitter.com/Fancyworldwide')}} className='team-social-btn'>
                        <img src={twitterImg}/>
                    </button>
                </div>
                {/* <div id='team-3' className='team-wrapper'>
                    <img src={img3} className='team-pfp' />
                    <div className='team-bottom'>
                        <p className='team-name tc-pink fs-ms ffam-lato fw-bold'>Jay aka Juicy Jay</p>
                        <p className='team-desc  tc-white fs-xs ffam-lato fw-light'>
                            Community Director & Healing Guru
                            <br />
                            Web3 Wellness Events Entrepreneur
                            <br />
                            Blockchain Babe since 2021
                            <br />
                            “Clearing Karma”
                        </p>
                    </div>
                    <button onClick={()=>{openLink('https://twitter.com/Clearing_Karma')}} className='team-social-btn'>
                        <img src={twitterImg}/>
                    </button>
                </div> */}
                <div id='team-4' className='team-wrapper'>
                    {/* <img src={teamProfile} className='team-personal-profile' /> */}
                    <img src={img4} className='team-pfp' />
                    <div className='team-bottom'>
                        <p className='team-name tc-pink fs-ms ffam-lato fw-bold'>Nicole aka Niki</p>
                        <p className='team-desc  tc-white fs-xs ffam-lato fw-light'>
                            Queen of Merch
                            <br />
                            Promotional Products Entrepreneur
                            <br />
                            Blockchain Babe since 2017
                            <br />
                            “She’s Got The Goods”
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TeamSection