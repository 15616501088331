import React, { useState, useEffect } from 'react'
import './TopSection.scss'
import img1 from '../../../../assets/images/top-section/main.webp'
import img2 from '../../../../assets/images/top-section/2.webp'
import img3 from '../../../../assets/images/top-section/3.webp'

import logo from '../../../../assets/images/logos/long-logo.png'
import tiktokImg from '../../../../assets/images/socials/tiktok.svg'
import instagramImg from '../../../../assets/images/socials/instagram.svg'
import twitterImg from '../../../../assets/images/socials/twitter.svg'

import MintSection from '../../../../components/mint-section/MintSection'
import InteractiveImg from './interactive-img/InteractiveImg'
function TopSection({ setWalletOpen }) {

    return (
        <div className='top-section'>
            {/* <span style={{color:'white',position:'fixed'}}>{width}</span>  */}
            <div className='top-section-inner'>
                <img className='top-section-logo' src={logo} />
                <div className='top-section-bottom'>
                    {/* <div className='top-section-img-wrapper'> */}
                        <InteractiveImg/>
                    {/* </div> */}
                    <div className='top-section-info-wrapper'>
                        <div className='top-section-title ffam-edo tc-pink fs-xl'>
                            Welcome to
                            {/* <br /> */}
                            <p style={{ marginTop: '-15px' }} />
                            Party Apes Club
                        </div>
                        <p className='top-section-info  ffam-lato tc-white fw-light fs-xs'>
                            Party Apes Club (PAC) is the FIRST NFT to provide healthcare access including both Medical & Mental Health Services.  PAC offers its members wellness benefits such as On-Demand Medical Care, Mental Health & Growth Tools, Self-Improvement applications and other exciting perks and utilities for members and their family.  PAC’s mission is to provide real world utility and benefits to help encourage and improve overall well-being in the Web3 space.  To become a member and receive wellness benefits, purchase a PAC digital token below via credit card or via a digital wallet.
                        </p>
                        <p className='top-section-encourage  ffam-edo tc-white fs-l'>
                            Let’s Honor Our Bodies and Minds.
                        </p>
                        <p className='top-section-encourage  ffam-edo tc-white fs-l'>
                            Let’s Move, Grow, and Thrive!
                        </p>
                        {/* <div className='top-section-btn-wrapper'>
                            <button id='top-section-connect-btn-1' className='top-section-connect-btn'>
                                Join Now Via Wallet
                            </button>
                            <button className='top-section-connect-btn'>
                                Join Now Via Credit Card
                            </button>
                        </div> */}
                        <MintSection setWalletOpen={setWalletOpen} />

                        {/* <div className='top-sectin-social-wrapper'>
                            <button onClick={()=>{openLink('https://twitter.com/PartyApesClub')}} className='social-btn' >
                                <img src={twitterImg}/>
                            </button>
                            <button onClick={()=>{openLink('https://www.instagram.com/partyapesclub/')}} className='social-btn'>
                                <img src={instagramImg}/>
                            </button>
                            <button onClick={()=>{openLink('https://www.tiktok.com/@partyapesclub')}} className='social-btn'>
                                <img src={tiktokImg}/>
                            </button>

                        </div> */}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TopSection