import React, { useEffect } from 'react'

import './Genesis.scss'
import nftImg from '../../../../assets/images/genesis.gif'

// import genesisMp4 from '../../../../assets/images/genesis.mp4'
// import genesisWebm from '../../../../assets/images/genesis.webm'

function Genesis() {
    // useEffect(() => {

    //     const aboutTop = document.getElementsByClassName('genesis-section')[0].getBoundingClientRect().top

    //     const app = window
    //     let genesisText = document.getElementsByClassName('genesis-text')[0]
    //     let genesisImg = document.getElementsByClassName('genesis-img')[0]
    //     app.addEventListener('scroll', () => {
    //         // if (window.innerWidth <= 1155) return;
    //         let currentTop = document.getElementsByClassName('genesis-section')[0]?.getBoundingClientRect().top
    //         const scrollFraction = 1 - (currentTop / (aboutTop * 0.8));
    //         console.log(currentTop)
    //         if (currentTop < aboutTop * 0.8) {
    //             genesisText.style.top = '0px';
    //             genesisText.style.opacity = '1';

    //             // genesisImg.style.right = '0px';
    //             // genesisImg.style.opacity = '1';
    //         }
    //     });
    // })

    useEffect(() => {

        const aboutTop = document.getElementsByClassName('genesis-section')[0].offsetTop

        const app = window
        const appImg = document.getElementsByClassName('app-bg')[0]
        const header = document.getElementsByClassName('header')[0]

        app.addEventListener('scroll', () => {
            // if (window.innerWidth <= 1155) return;
            let currentTop = document.getElementsByClassName('genesis-section')[0]?.getBoundingClientRect().top
            // console.log(currentTop,aboutTop)
           
            const scrollFraction = 1 - (currentTop / (aboutTop * 0.8));
            const down = 0.75 * scrollFraction

            if (currentTop < aboutTop ) {
                header.style.background = 'rgba(0,0,0,0.7)'
                if (window.innerWidth > 500) {
                    header.style.height = '85px'
                }
                else {
                    header.style.height = '72px'
                }
            }
            else{
                header.style.background = 'transparent'
                header.style.height = '140px'
            }
            if (currentTop < aboutTop * 0.8 && down < 0.75 && down > 0) {
                appImg.style.transform = `scale3d(${1.75 - down},${1.75 - down},${1.75 - down})`
            }
        });
    })


    let observer = new IntersectionObserver(function (entries) {
        let genesisText = document.getElementsByClassName('genesis-text')[0]
        let genesisImg = document.getElementsByClassName('genesis-img')[0]

        const animate = () => {
            genesisText.style.top = '0px';
            genesisText.style.opacity = '1';
            genesisImg.style.right = '0px';
            genesisImg.style.opacity = '1';
        }


        for (var i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting === true) {
                if (window.innerWidth > 669) {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {
                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()
                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
                else {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {

                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()

                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
            }
        }
    }, { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });

    let observer2 = new IntersectionObserver(function (entries) {
        let genesisText = document.getElementsByClassName('genesis-bottom-text')[0]
        let genesisImg = document.getElementsByClassName('genesis-video')[0]

        const animate = () => {
            genesisText.style.transform = 'scale3d(1,1,1)';
            genesisImg.style.left = '0px';
            genesisImg.style.top = '0px';
            genesisImg.style.top = '0px';
            genesisImg.style.transform = 'scale3d(1,1,1)';
            genesisImg.style.opacity = '1';
        }


        for (var i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting === true) {
                if (window.innerWidth > 669) {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {
                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()
                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
                else {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {

                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()

                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
            }
        }
    }, { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });

    useEffect(() => {
        observer.observe(document.getElementsByClassName('genesis-top')[0]);
        // observer2.observe(document.getElementsByClassName('genesis-bottom')[0]);
    }, [])

    const openLink=(link)=>{
        window.open(link)
    }

    return (
        <div className='genesis-section'>
            <div className='genesis-inner'>
                <div className='genesis-top'>
                    <div className='genesis-text ffam-lato tc-white fw-light fs-s'>
                        <div className='genesis-title ffam-edo tc-pink fs-xls'>
                            THE GENESIS COLLECTION
                        </div>
                        <p className='fs-s'>
                            This limited collection of only 1,111 PAC apes will consist of several exclusive traits and benefits that will not be seen in Phase 2 of the PAC collection.  This collection is in line with our mission, purpose, and belief of supporting one another during the bad times so that we can grow and thrive together during the good times.  To be a member of the PAC, means that you are part of a conscious collective that welcomes all, celebrates diversity, and the importance of each members well-being is at the forefront of our healthy and thriving commUNITY. 
                        </p>

                        Click  <a href="https://partyapesclub.medium.com/our-manifesto-636bf84d2dab">here</a> to read our Manifesto.
                    </div>
                    <div className='genesis-img'>
                        <img src={nftImg} />
                    </div>
                </div>
               
            </div>
        </div>
    )
}

export default Genesis