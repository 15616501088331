import React,{useEffect} from 'react'
import img1 from '../../../../assets/images/partner-logo/ledger.png'
import img2 from '../../../../assets/images/partner-logo/teladoc-logo.png'
import img3 from '../../../../assets/images/partner-logo/bang-logo.png'
import img4 from '../../../../assets/images/partner-logo/impact.png'
import img5 from '../../../../assets/images/partner-logo/vmd-logo.png'

import './PartnerLogo.scss'

function partner() {
    let observer= new IntersectionObserver(function(entries) {
        let partner1=document.getElementById('partner-1')
        let partner2=document.getElementById('partner-2')
        let partner3=document.getElementById('partner-3')
        let partner4=document.getElementById('partner-4')
        let partner5=document.getElementById('partner-5')
        // let partner6=document.getElementById('partner-6')

        const animate=()=>{
            partner1.style.top='0px';
            partner1.style.opacity='1';
            partner1.style.transform='scale3d(1,1,1)';

            partner2.style.top='0px';
            partner2.style.opacity='1';
            partner2.style.transform='scale3d(1,1,1)';

            partner3.style.top='0px';
            partner3.style.opacity='1';
            partner3.style.transform='scale3d(1,1,1)';

            partner4.style.top='0px';
            partner4.style.opacity='1';
            partner4.style.transform='scale3d(1,1,1)';

            partner5.style.top='0px';
            partner5.style.opacity='1';
            partner5.style.transform='scale3d(1,1,1)';
            
            // partner6.style.top='0px';
            // partner6.style.opacity='1';
            // partner6.style.transform='scale3d(1,1,1)';

        }


        for(var i=0;i<entries.length;i++){
            if(entries[i].isIntersecting===true){
                if(window.innerWidth>669){
                    switch(Math.round(entries[i].intersectionRatio*10)){
                        case 1:
                            animate()
                            
                            break;
                        case 2:
                            animate()
                            break;
                        case 3:
                            animate()
                            
                            break;
                            case 4:
                                animate()
                                
                                break;
                    }
                }
                else{
                    switch(Math.round(entries[i].intersectionRatio*10)){
        
                        case 1:
                            animate()
                            
                            break;
                        case 2:
                            animate()
                            
                            break;
                        case 3:
                            animate()
                            
                            break;
                        case 4:
                            animate()
                            
                            break;
                    }
                }
            }
        }
    },{ threshold: [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1] });

    // useEffect(()=>{
    //     observer.observe(document.getElementsByClassName('partner-section')[0]);
    // },[])


    return (
        <div className='partner-section'>
        
            <div className='partner-section-inner'>
                <div id="partner-1" className='partner-logo-wrapper'>
                    <img src={img1} />
                </div>

                <div  id="partner-2" className='partner-logo-wrapper'>
                    <img src={img2} />
                </div>

                <div  id="partner-3" className='partner-logo-wrapper'>
                    <img src={img3} />
                </div>

                <div id="partner-4" className='partner-logo-wrapper'>
                    <img src={img4} />
                </div>

                <div id="partner-5" className='partner-logo-wrapper'>
                    <img src={img5} />
                </div>
            </div>
        </div>
    )
}

export default partner