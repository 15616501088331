import React, { useEffect } from 'react'
import './Roadmap.scss'

import appImg from '../../../../assets/images/roadmap/app.webp'
import partyMixImg from '../../../../assets/images/roadmap/PartyMix.webp'

import genesisMp4 from '../../../../assets/images/genesis.mp4'
import genesisWebm from '../../../../assets/images/genesis.webm'

function Roadmap() {
    let observer2 = new IntersectionObserver(function (entries) {
        let img = document.getElementsByClassName('roadmap-app-img')[0]

        const animate = () => {
            if (window.innerWidth > 669) {
                img.style.transform = 'rotateZ(21deg) rotateY(-41deg) scale3d(1,1,1)';
            }
        }


        for (var i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting === true) {
                if (window.innerWidth > 669) {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {

                        case 2:
                            animate()
                            break;
                        case 3:
                            animate()

                            break;
                    }
                }
            }
        }
    }, { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });

    let observer3 = new IntersectionObserver(function (entries) {
        // let img = document.getElementsByClassName('roadmap-bottom-img')[0]
        let roadmap1 = document.getElementById('roadmap-bottom-phase-1')
        let roadmap2 = document.getElementById('roadmap-bottom-phase-2')
        let roadmap3 = document.getElementById('roadmap-bottom-phase-3')
        let roadmap4 = document.getElementById('roadmap-bottom-phase-4')

        const animate = () => {

            // img.style.transform = 'scale3d(1,1,1)';
            roadmap1.style.top = '0px';
            roadmap1.style.opacity = '1';
            roadmap1.style.transform = 'scale3d(1,1,1)';

            roadmap2.style.top = '0px';
            roadmap2.style.opacity = '1';
            roadmap2.style.transform = 'scale3d(1,1,1)';

            roadmap3.style.top = '0px';
            roadmap3.style.opacity = '1';
            roadmap3.style.transform = 'scale3d(1,1,1)';

            roadmap4.style.top = '0px';
            roadmap4.style.opacity = '1';
            roadmap4.style.transform = 'scale3d(1,1,1)';
        }


        for (var i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting === true) {
                if (window.innerWidth > 669) {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {
                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()
                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
                else {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {

                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()

                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
            }
        }
    }, { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });

    let observer = new IntersectionObserver(function (entries) {
        for (var i = 0; i < entries.length; i++) {
            // console.log(entries[i].intersectionRect.bottom)
            if (entries[i].intersectionRect.top > 0 && entries[i].isIntersecting === true) {
                switch (Math.round(entries[i].intersectionRatio * 10)) {
                    //   case 0:
                    //     entries[i].target.style.top='100px';

                    //     break;
                    //   case 1:
                    //     entries[i].target.style.top='90px';
                    //     entries[i].target.style.opacity=`0.3`;
                    //     entries[i].target.style.right='0px';
                    //     break;
                    //   case 2:
                    //     entries[i].target.style.top='80px';
                    //     entries[i].target.style.opacity=`0.35`;
                    //     entries[i].target.style.right='0px';
                    //     break;
                    //   case 3:
                    //     entries[i].target.style.top='70px';
                    //     entries[i].target.style.opacity=`0.45`;
                    //     entries[i].target.style.right='0px';
                    //     break;
                    //   case 4:
                    //     entries[i].target.style.top='60px';
                    //     entries[i].target.style.opacity=`0.55`;
                    //     entries[i].target.style.right='0px';
                    //     break; 
                    //   case 5:
                    //     entries[i].target.style.top='50px';
                    //     entries[i].target.style.opacity=`0.65`;
                    //     entries[i].target.style.right='0px';
                    //     break; 
                    //   case 6:
                    //     entries[i].target.style.top='40px';
                    //     entries[i].target.style.opacity=`0.75`;
                    //     entries[i].target.style.right='0px';
                    //     break;
                    //   case 7:
                    //     entries[i].target.style.top='30px';
                    //     entries[i].target.style.opacity=`0.85`;
                    //     entries[i].target.style.right='0px';
                    //     break;
                    //   case 8:
                    //     entries[i].target.style.top='20px';
                    //     entries[i].target.style.opacity=`0.95`;
                    //     entries[i].target.style.right='0px';
                    //     break;
                    //   case 9:
                    //     entries[i].target.style.top='10px';
                    //     entries[i].target.style.opacity=`1`;
                    //     entries[i].target.style.right='0px';
                    //     break;
                    case 10:
                        entries[i].target.style.top = '0px';
                        entries[i].target.style.opacity = `1`;
                        entries[i].target.style.right = '0px ';
                        break;
                    default:
                        break;
                }
            }
        }
    }, { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });

    let observer4 = new IntersectionObserver(function (entries) {
        let genesisText = document.getElementsByClassName('genesis-bottom-text')[0]
        let genesisImg = document.getElementsByClassName('genesis-video')[0]

        const animate = () => {
            genesisText.style.transform = 'scale3d(1,1,1)';
            genesisImg.style.left = '0px';
            genesisImg.style.top = '0px';
            genesisImg.style.top = '0px';
            genesisImg.style.transform = 'scale3d(1,1,1)';
            genesisImg.style.opacity = '1';
        }


        for (var i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting === true) {
                if (window.innerWidth > 669) {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {
                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()
                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
                else {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {

                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()

                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
            }
        }
    }, { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });



    useEffect(() => {
        observer.observe(document.getElementById('roadmap-1'));
        observer.observe(document.getElementById('roadmap-2'));
        observer.observe(document.getElementById('roadmap-3'));
        observer.observe(document.getElementById('roadmap-4'));
        observer.observe(document.getElementById('roadmap-5'));
        observer.observe(document.getElementById('roadmap-6'));
        observer.observe(document.getElementById('roadmap-7'));
        // observer.observe(document.getElementById('roadmap-8'));
        // observer.observe(document.getElementById('roadmap-7'));
        // observer.observe(document.getElementById('roadmap-8'));
        // observer.observe(document.getElementById('roadmap-9'));
        // observer.observe(document.getElementById('roadmap-10'));
        // observer.observe(document.getElementById('roadmap-11'));
        // observer.observe(document.getElementById('roadmap-12'));
        // observer.observe(document.getElementById('roadmap-13'));
        // observer.observe(document.getElementById('roadmap-14'));
        // observer.observe(document.getElementById('roadmap-15'));
        // observer.observe(document.getElementById('roadmap-16'));
        // observer.observe(document.getElementById('roadmap-17'));
        // observer.observe(document.getElementById('roadmap-18'));
        // observer.observe(document.getElementById('roadmap-19'));
        // observer.observe(document.getElementById('roadmap-20'));
        // observer.observe(document.getElementById('roadmap-21'));
        // observer.observe(document.getElementById('roadmap-22'));

        observer2.observe(document.getElementsByClassName('roadmap')[0]);
        observer3.observe(document.getElementsByClassName('roadmap-bottom')[0]);
        observer4.observe(document.getElementsByClassName('genesis-bottom')[0]);

    }, [])

    const openLink = (link) => {
        window.open(link)
    }
    return (
        <div className='roadmap' >
            <div className='roadmap-inner'>
                <p className='genesis-perk-title  ffam-edo tc-white fs-l'>
                    Membership Benefits & Utility
                </p>
                <div className='genesis-bottom'>
                    <video className='genesis-video' muted loop playsInline autoPlay>
                        <source src={genesisWebm} />
                        <source src={genesisMp4} />
                    </video>

                    <div className='genesis-bottom-text ffam-lato tc-white fw-light fs-s'>
                        {/* <div className='indented-section'><div>•</div><div>Access to PAC members-only events during Art Basel Miami 2022</div></div> */}
                        <div className='indented-section'><div>•</div><div>Your PAC NFT doubles as your digital membership pass to health & wellness benefits and all PAC events</div></div>
                        <div className='indented-section'><div>•</div><div>Receive On-Demand Self-Care and Wellness Tools for you and your family via VMD.  Request a medical consultation via Teladoc, speak with certified therapists, life coaches, and specialists that focus on specific lifestyle areas.</div></div>
                        <div className='indented-section'><div>•</div><div>Get access to online tools that help improve lives, spark healing, inspire community and personal growth.</div></div>
                        <div className='indented-section'><div>•</div><div>Receive special discounts on wellness products and services</div></div>
                        <div className='indented-section'><div>•</div><div>Access to giveaways and Limited Edition merch</div></div>
                        <div className='indented-section'><div>•</div><div>Genesis members skip the line at any PAC & partner events</div></div>
                        <div className='indented-section'><div>•</div><div>Make an impact with PAC!  Participate in the PAC Pay It Forward Program by donating your benefits to a family in need while keeping your NFT</div></div>
                        <div className='indented-section'><div>•</div><div>Party and celebrate your wins with us at our Annual Basel Bash during Miami Art Basel</div></div>
                    </div>
                    {/* <button onClick={() => { openLink('https://perk.shop/pac') }} className='blue-btn'>
                        View Perk Shop
                    </button> */}
                </div>
            </div>

            <div className='roadmap-title ffam-edo tc-pink fs-xls'>
                ROADMAP 1.0
            </div>
            <div className='roadmap-inner ffam-lato tc-white fw-light'>
                <p className='roadmap-sub-title  ffam-edo tc-white fs-l'>
                    Pre-Launch
                </p>
                <div className='roadmap-top ffam-lato tc-white fw-light fs-xs'>
                    <div className='roadmap-top-text'>
                    <div className='roadmap-goal' id="roadmap-2">
                            Partner with Ledger to provide our community with education on blockchain safety & security via our Twitter spaces
                        </div>
                        <div className='roadmap-goal' id="roadmap-1">
                            Custom PAC Ledger hardware wallets to be used as giveaways to help keep community members safe in Web3
                        </div>
                        
                        <div className='roadmap-goal' id="roadmap-3">
                            Charitable contributions for Medical & Mental Health Therapy are donated to community members & partners
                        </div>
                        <div className='roadmap-goal' id="roadmap-4">
                            Launch weekly community spaces via Twitter to provide education, entertainment, and community support
                        </div>
                        <div className='roadmap-goal' id="roadmap-5">
                            Launch of PAC Perk Shop where members can preview benefits and upcoming giveaways
                        </div>
                        <div className='roadmap-goal' id="roadmap-6">
                            Launch AR “PAC APE” App to the public to help make Web3 fun
                        </div>
                        <div className='roadmap-goal' id="roadmap-7">
                            Provide exclusive access to partner events
                        </div>
                   
                    </div>


                    <img className='roadmap-app-img' src={appImg} />
                </div>
                <p className='roadmap-sub-title-2  ffam-edo tc-white fs-l'>
                    Post-Launch
                </p>
                <div className='roadmap-bottom fs-xs'>
                    {/* <div className='roadmap-bottom-header'>
                       
                    </div> */}
                    {/* <div className='roadmap-bottom-body'> */}
                    <div id="roadmap-bottom-phase-1" className='roadmap-bottom-phase'>
                        <div className='roadmap-bottom-phase-header  ffam-lato fs-m fw-semi-bold'>
                            Phase 1
                        </div>
                        <div className='roadmap-bottom-phase-text tc-white ffam-lato fs-xxs'>
                            <div className='indented-section'><div>•</div><div>The PAC Perk Shop opens for claiming of benefits and giveaways</div></div>
                            <div className='indented-section'><div>•</div><div>PAC Launch Party at Miami Art Basel for Members Only</div></div>
                            <div className='indented-section'><div>•</div><div>PAC Gallery Event at Miami Art Basel for Members Only</div></div>
                            <div className='indented-section'><div>•</div><div>Host PAC Members Only spaces with special guest speakers via our Twitter</div></div>
                        </div>
                    </div>
                    <div id="roadmap-bottom-phase-2" className='roadmap-bottom-phase'>
                        <div className='roadmap-bottom-phase-header  ffam-lato fs-m fw-semi-bold'>
                            Phase 2
                        </div>
                        <div className='roadmap-bottom-phase-text tc-white ffam-lato fs-xxs'>
                            {/* <div className='indented-section'><div>•</div><div>Planning begins for PAC NFTNYC party</div></div> */}
                            <div className='indented-section'><div>•</div><div>Release of remaining collection</div></div>

                            <div className='indented-section'><div>•</div><div>PAC Merch Shop opens</div></div>
                            <div className='indented-section'><div>•</div><div>Surprise NFT drop</div></div>
                            <div className='indented-section'><div>•</div><div>The Meeyami Jungle opens for PAC Members Only</div></div>

                            <div className='indented-section'><div>•</div><div>Additional partnership and member benefits announced throughout roadmap</div></div>
                        </div>
                    </div>
                    <div id="roadmap-bottom-phase-3" className='roadmap-bottom-phase'>
                        <div className='roadmap-bottom-phase-header  ffam-lato fs-m fw-semi-bold'>
                            Phase 3
                        </div>
                        <div className='roadmap-bottom-phase-text tc-white ffam-lato fs-xxs'>
                            <div className='indented-section'><div>•</div><div>Submissions for Worldwide Ambassadors Program begins</div></div>
                            <div className='indented-section'><div>•</div><div>Planning begins for IRL events worldwide</div></div>
                            <div className='indented-section'><div>•</div><div>Charitable contributions are announced throughout roadmap</div></div>
                            <div className='indented-section'><div>•</div><div>Additional surprise giveaways to music festivals and partner events announced throughout roadmap</div></div>
                        </div>
                    </div>
                    <div id="roadmap-bottom-phase-4" className='roadmap-bottom-phase'>
                        <div className='roadmap-bottom-phase-header  ffam-lato fs-m fw-semi-bold'>
                            Phase 4
                        </div>
                        <div className='roadmap-bottom-phase-text tc-white ffam-lato fs-xxs'>
                            <div className='indented-section'><div>•</div><div>Planning for PAC 2023 Miami Art Basel Party begins</div></div>
                            <div className='indented-section'><div>•</div><div>PAC enters the Metaverse</div></div>
                            <div className='indented-section'><div>•</div><div>PAC Roadmap 2.0 is released</div></div>
                            <div className='indented-section'><div>⁕</div><div>Membership benefits and utility will increase throughout our roadmap and include many that cannot be announced yet.  Hint:  They have not been done before.</div></div>

                        </div>
                    </div>
                    {/* </div> */}
                </div>

            </div>




        </div>
    )
}

export default Roadmap