import React, { useEffect ,useState} from 'react'
import './ContactUs.scss'

import contactImg from '../../../../assets/images/mushroomape.webp'
import appleIcon from '../../../../assets/images/applestore.svg'
import playstore from '../../../../assets/images/playstore.svg'

import { toast } from 'react-toastify';

// import emailjs from '@emailjs/browser';

function StorySection() {
    let observer = new IntersectionObserver(function (entries) {
        let img = document.getElementsByClassName('contact-section-image')[0]

        const animate = () => {
            img.style.transform = 'scale3d(1,1,1)';
        }


        for (var i = 0; i < entries.length; i++) {
            if (entries[i].isIntersecting === true) {
                if (window.innerWidth > 669) {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {
                        case 1:
                            animate()
                            break;
                        case 2:
                            animate()
                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()
                            break;
                    }
                }
                else {
                    switch (Math.round(entries[i].intersectionRatio * 10)) {

                        case 1:
                            animate()

                            break;
                        case 2:
                            animate()

                            break;
                        case 3:
                            animate()

                            break;
                        case 4:
                            animate()

                            break;
                    }
                }
            }
        }
    }, { threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });
    useEffect(() => {
        observer.observe(document.getElementsByClassName('contact-section')[0]);
    }, [])
    const openLink = (link) => {
        window.open(link)
    }


    const[name,setName]=useState('');
    const[email,setEmail]=useState('');
    const[message,setMessage]=useState('');

    const sendEmail=(e)=>{
        e.preventDefault();
       
        // if(message!==''&&email!==''&&name!==''){
            
            emailjs.sendForm('service_zsnlq5e', 'template_sc0avmf', e.target, 'FvW0CZd8R1vln3mlV')
            .then((result) => {
                // alert('Message sent successfully.')
                toast.success('Message sent successfully.');
            }, (error) => {
                toast.error(error.text);
            });
            e.target.reset()
        // }
        // else{
        //     alert('Not all required fields have been filled.')
        // }
        console.log('what')
        
    }
    return (
        <div className='contact-section'>
            <div className='contact-section-inner'>

                <form onSubmit={(e)=>{sendEmail(e)}} className='contact-section-left'>
                    <div className='contact-title-holder'>
                        <div className='contact-title tc-pink fs-xlm ffam-edo'>
                            CONTACT US
                        </div>
                    </div>

                    <input placeholder='Full Name' name='name' required/>
                    <input placeholder='Email' name='email' required  type='email'/>
                    <textarea placeholder='Message' name='message' required/>
                    <div className='contact-btn-wrapper'>
                        <button type='submit' className='contact-section-submit'>SUBMIT</button>
                    </div>
                    <p className='download-txt ffam-edo tc-white fs-l'>
                        PARTY WITH PAC!
                        <br />
                        DOWNLOAD THE “PACAPE” APP.
                    </p>
                    <div className='contact-download-app-wrapper'>
                        <img src={appleIcon} onClick={()=>{openLink('https://apps.apple.com/us/app/pacape/id1624135095')}}/>
                        <img src={playstore} onClick={()=>{openLink('https://play.google.com/store/apps/details?id=com.blockchainbabesworldwide.pacape')}}/>
                    </div>
                </form>
                <div className='contact-section-image-wrapper'>
                    <img className='contact-section-image' src={contactImg} />
                </div>
            </div>

        </div>
    )
}

export default StorySection
