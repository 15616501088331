import React, { useState, useEffect } from 'react'
import './MintSection.scss'

import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../redux/data/dataActions";
import { toast } from 'react-toastify';
import Web3EthContract from "web3-eth-contract";

import whitelistedAddresses from '../../addresses.json'
import MerkleTree from 'merkletreejs'
import keccak256 from 'keccak256'

import loading from '../../assets/images/loading.svg'
import Timer from '../../misc/timer'
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

const showCrossmint = true;

function TopSection({ setWalletOpen }) {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);
    const [claimingNft, setClaimingNft] = useState(false);
    const [mintAmount, setMintAmount] = useState(1);
    const [maxPerWallet, setMaxPerWallet] = useState(null);
    const [limit, setLimit] = useState(null)

    const [refresh, setRefresh] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false)
    const [addressWhitelisted, setAddressWhitelisted] = useState(false)

    const [saleConfig, setSaleConfig] = useState(null)
    const [cost, setCost] = useState(null)

    const [presaleActive, setPresaleActive] = useState(null)
    const [publicActive, setPublicActive] = useState(null)
    const [timer, setTimer] = useState(null)


    // const [ethPrice, setEthPrice] = useState(null)

 

    // useEffect(() => {
    //     fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
    //     .then(response => response.json())
    //     .then((data)=>{
    //         console.log(data)
    //         setEthPrice(data.USD)
    //     })
    //     .catch((err)=>{
    //     })
    //     setEthPrice()
    // },[])


    const [CONFIG, SET_CONFIG] = useState({
        CONTRACT_ADDRESS: "",
        NETWORK: {
            NAME: "",
            SYMBOL: "",
            ID: 0,
        },
        GAS_LIMIT: 0,
    });
    const getConfig = async () => {
        const configResponse = await fetch("/config/config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        });
        const config = await configResponse.json();
        SET_CONFIG(config);
    };

    useEffect(() => {
        getConfig();

    }, []);

    const presaleMint = () => {

        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);


        const whiteListAddressesLeaves = whitelistedAddresses.map((x) => keccak256(x))
        const merkletree = new MerkleTree(whiteListAddressesLeaves, keccak256, { sortPairs: true })
        const merklePoof = merkletree.getHexProof(keccak256(blockchain.account))

        toast.info(`Minting your ${mintAmount > 1 ? 'Party Ape Club NFTs' : 'Party Ape Club NFT'}...`);
        setClaimingNft(true);


        blockchain.smartContract.methods.presaleMint(merklePoof, mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                toast.error("Sorry, something went wrong please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                toast.success(() =>
                    <span>
                        Party Ape Club NFT Mint Successful! Visit <a href="https://opensea.io/">opensea.io</a> to view your NFT.
                    </span>
                );
                setClaimingNft(false);
                setRefresh(refresh + 1)
                setMintAmount(1)
            });
    };

    const publicMint = () => {

        let gasLimit = CONFIG.GAS_LIMIT;
        let totalCostWei = String(cost * mintAmount);
        let totalGasLimit = String(gasLimit * mintAmount);

        setClaimingNft(true);
        toast.info(`Minting your ${mintAmount > 1 ? 'Party Ape Club NFTs' : 'Party Ape Club NFT'}...`);

        blockchain.smartContract.methods
            .publicMint(mintAmount)
            .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
                value: totalCostWei,
            })
            .once("error", (err) => {
                console.log(err);
                toast.error("Sorry, something went wrong please try again later.");
                setClaimingNft(false);
            })
            .then((receipt) => {
                console.log(receipt);
                toast.success(() =>
                    <span>
                        Party Ape Club NFT Mint Successful! Visit <a href="https://opensea.io/">opensea.io</a> to view your NFT.
                    </span>
                );
                setClaimingNft(false);
                setRefresh(refresh + 1)
                setMintAmount(1)
            });
    };

    const initConnect = async (connectedAccount) => {
        // console.log(connectedAccount)

        try {
            setAddressWhitelisted(isWhitelisted(connectedAccount))

            const presaleCost = await blockchain.smartContract.methods.presaleCost().call();
            const publicSaleCost = await blockchain.smartContract.methods.publicSaleCost().call();
            const collabCost = await blockchain.smartContract.methods.collabCost().call();

            const saleConfig = Number(await blockchain.smartContract.methods.saleConfig().call());

            //   const presaleActive=await blockchain.smartContract.methods.presaleActive().call();
            //   const publicActive=await blockchain.smartContract.methods.publicSaleActive().call();


            const maxMintAmountPerPresaleAccount = await blockchain.smartContract.methods.maxAmountPerPresaleAccount().call();
            const maxMintAmountPerPublicAccount = await blockchain.smartContract.methods.maxAmountPerPublicSaleAccount().call();
            const maxMintAmountPerCollabAccount = await blockchain.smartContract.methods.maxAmountPerCollabAccount().call();


            console.log(saleConfig)
            //presaleActive
            if (saleConfig === 1 || saleConfig === 3) {
                console.log('working')
                setMaxPerWallet(maxMintAmountPerPresaleAccount)
                setCost(presaleCost)
                setPresaleActive(true)
            }
            //publicActive
            else if (saleConfig === 2 || saleConfig === 4) {
                setMaxPerWallet(maxMintAmountPerPublicAccount)
                setCost(publicSaleCost)
                setPublicActive(true)
            }
            setSaleConfig(saleConfig)
            setDataLoaded(true)
        }
        catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {
        if (blockchain.smartContract === null) return;

        initConnect(blockchain.account)

        if (timer !== null) {
            timer.stop()
        }

        let timer2 = new Timer(() => {
            initConnect(blockchain.account)
        }, [10000]);

        setTimer(timer2)
    }, [blockchain.account, blockchain.smartContract, refresh])

    const isWhitelisted = (account) => {
        const whiteListAddressesLeaves = whitelistedAddresses.map((x) => keccak256(x));
        const merkletree = new MerkleTree(whiteListAddressesLeaves, keccak256, { sortPairs: true })
        const merklePoof = merkletree.getHexProof(keccak256(account));
        const rootNode = merkletree.getRoot().toString('hex');
        let whitelisted = merkletree.verify(merklePoof, keccak256(account), rootNode)
        // console.log(whitelisted)
        return whitelisted
    }



    useEffect(() => {
        if (blockchain.account === null || dataLoaded === false) return;

        if (presaleActive === true) {
            blockchain.smartContract.methods.numberMinted(blockchain.account).call().then((result) => {
                let limit = Number(maxPerWallet) - result;
                // console.log(typeof(maxPerWallet))
                // console.log(limit)
                if (limit <= 0) {
                    setLimit(0)
                }
                else {
                    setLimit(limit)
                }
            })
        }
        else if (publicActive === true) {
            blockchain.smartContract.methods.getPublicAmountMinted(blockchain.account).call().then((result) => {
                let limit = Number(maxPerWallet) - result;

                if (limit <= 0) {
                    setLimit(0)
                }
                else {
                    setLimit(limit)
                }
            })
        }
    }, [refresh, blockchain.account, dataLoaded, presaleActive, publicActive, maxPerWallet])

    const decrementMintAmount = () => {
        let newMintAmount = mintAmount - 1;
        if (newMintAmount < 1) {
            newMintAmount = 1;
        }
        setMintAmount(newMintAmount);
    };

    const incrementMintAmount = () => {
        let newMintAmount = mintAmount + 1;
        if (newMintAmount > limit) {
            newMintAmount = limit;
        }
        setMintAmount(newMintAmount);
    };

    // console.log(presaleActive, addressWhitelisted, limit, maxPerWallet)

    //haven't set up collab

    const [days, setDays] = useState('')
    const [hours, setHours] = useState('')
    const [minutes, setMinutes] = useState('')
    const [seconds, setSeconds] = useState('')
    const [timerVisible, setTimerVisible] = useState(null)
    // Update the count down every 1 second
    useEffect(() => {
        var countDownDate = new Date(Date.UTC(2022, 10, 10, 16)).getTime();
        // var countDownDate = new Date(Date.UTC(2022, 10, 10, 13,36)).getTime();


        const time = () => {
            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            setTimerVisible(true)
            // console.log(distance)
            if (seconds > 9) {
                setSeconds(`${seconds}`)
            }
            else {
                setSeconds(`0${seconds}`)
            }
            if (minutes > 9) {
                setMinutes(`${minutes}`)
            }
            else {
                setMinutes(`0${minutes}`)
            }
            if (hours > 9) {
                setHours(`${hours}`)
            }
            else {
                setHours(`0${hours}`)
            }
            if (days > 9) {
                setDays(`${days}`)
            }
            else {
                setDays(`0${days}`)
            }

            if (distance < 0) {
                clearInterval(timer);
                setTimerVisible(false)
                //   setTimerFinished(true)
            }

        }
        time()
        var timer = setInterval(function () {

            time()
        }, 1000);

    }, [])


    
    return (
        <div className='mint-section'>
            {
                blockchain.account === null &&
                <div className='mint-section-btn-wrapper'>
                    {
                        timerVisible === true ?
                            <div className='mint-section-time-wrapper'>
                                <button className='mint-section-time-btn'>
                                    {days}
                                    <span>DAYS</span>
                                </button>
                                <button className='mint-section-time-btn'>
                                    {hours}
                                    <span>HOURS</span>
                                </button>
                                <button className='mint-section-time-btn'>
                                    {minutes}
                                    <span>MINUTES</span>

                                </button>
                                <button className='mint-section-time-btn'>
                                    {seconds}
                                    <span>SECONDS</span>

                                </button>
                            </div>
                            : timerVisible === false ?
                                <div >
                                    <button onClick={() => { setWalletOpen(true) }} id='mint-section-connect-btn-1' className='mint-section-connect-btn'>
                                        Join Now Via Wallet
                                    </button>
                                    <p className='mint-price-connect tc-white ffam-lato tc-white fs-s fw-bold'>0.11 ETH</p>

                                </div>
                                : null
                    }
                    <div>

                        <CrossmintPayButton
                            clientId="4ff8564e-b786-4c38-8447-8bc4c1588bd0"
                            mintConfig={{"type":"erc-721","totalPrice":"0.11"}}
                            style={{ fontFamily: 'Lato', borderRadius: 10 }}
                            className='mint-section-connect-btn'
                            theme='light'
                        />
                        {/* {
                            ethPrice?
                            <p className='mint-price-connect tc-white ffam-lato tc-white fs-s fw-bold'>${(0.11*ethPrice).toFixed(2)}</p>
                            :null
                        } */}
                    </div>
                    {/* <button onClick={() => { setWalletOpen(true) }} id='mint-section-connect-btn-1' className='mint-section-connect-btn'>
                        Join Now Via Wallet
                    </button> */}

                    {/* <CrossmintPayButton
                        className='mint-section-connect-btn'
                        style={{fontFamily:'Lato',borderRadius:10}}
                        
                        theme='light'
                        clientId="77d60667-e5f1-42eb-aa41-71aad4ed3eab"
                        mintConfig={{"type":"erc-721","totalPrice":"0.28"}}
                        environment="staging"
                    /> */}
                </div>
            }
            {

                blockchain.smartContract !== null && (dataLoaded === false || limit === null) &&
                <div className='mint-section-btn-wrapper'>
                    <button className='mint-section-connect-btn mint-btn'>
                        <img src={loading} />
                    </button>
                </div>
            }
            {
                blockchain.smartContract !== null && presaleActive === true && addressWhitelisted === true && limit > 0 &&
                <div>
                    <div className='mint-section-amount-wrapper'>
                        <button onClick={() => { decrementMintAmount() }}>
                            <p>-</p>
                        </button>
                        <div >
                            <p className='ffam-lato tc-white fs-ms fw-bold'>{mintAmount} </p>
                        </div>
                        <button onClick={() => { incrementMintAmount() }}>
                            <p>+</p>
                        </button>
                    </div>
                    <button onClick={() => { presaleMint() }} className='mint-section-connect-btn mint-btn'>
                        {
                            claimingNft ?
                                <img src={loading}></img>
                                :
                                "MINT NOW"
                        }
                    </button>
                    <p className='mint-price tc-white ffam-lato tc-white fs-s fw-bold'>{Number(cost) / 1000000000000000000} ETH * {mintAmount}</p>
                </div>
            }
            {
                blockchain.smartContract !== null && presaleActive === true && addressWhitelisted === false &&
                <div className='mint-section-btn-wrapper'>
                    <button className='mint-section-connect-btn mint-btn'>
                        Address Not Whitelisted
                    </button>
                </div>
            }
            {
                blockchain.smartContract !== null && limit === 0 &&
                <div className='mint-section-btn-wrapper'>
                    <button className='mint-section-connect-btn mint-btn'>
                        Mint Limit Exceeded
                    </button>
                </div>
            }


            {
                blockchain.smartContract !== null && publicActive === true && limit > 0 &&
                <div>
                    <div className='mint-section-amount-wrapper'>
                        <button onClick={() => { decrementMintAmount() }}>
                            <p>-</p>
                        </button>
                        <div >
                            <p className='ffam-lato tc-white fs-ms fw-bold'>{mintAmount} </p>
                        </div>
                        <button onClick={() => { incrementMintAmount() }}>
                            <p>+</p>
                        </button>
                    </div>
                    <button onClick={() => { publicMint() }} className='mint-section-connect-btn mint-btn'>
                        {
                            claimingNft ?
                                <img src={loading}></img>
                                :
                                "MINT NOW"
                        }
                    </button>
                    <p className='mint-price tc-white ffam-lato tc-white fs-s fw-bold'>{Number(cost) / 1000000000000000000} ETH * {mintAmount}</p>

                </div>
            }
        </div>
    )
}

export default TopSection