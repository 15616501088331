import './App.scss';
import { useEffect } from 'react';
import smoothscroll from "smoothscroll-polyfill";
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import WelcomePage from './pages/welcome-page/WelcomePage';
import Home from './pages/home/Home'
import {Routes ,Route} from 'react-router-dom'


function App(){

  useEffect(() => {
    smoothscroll.polyfill();
  }, []);

  return (
    <div className="App">
      <ToastContainer style={{top:95}} position="top-right" autoClose={5000} closeOnClick />
      <Routes>
        <Route path="/" element={<WelcomePage/>} />
        <Route path="/home" element={<Home/>} />
      </Routes>
    </div>
  );
}

export default App;
