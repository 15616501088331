import React from 'react'
import './Footer.scss'


function Footer() {
    return (
        <div className='footer'>
            <div className='footer-inner'>
                <p className='ffam-lato tc-gray fw-light fs-xs'>Copyright @Blockchain Babes Worldwide, LLC. 100% Female Owned & Operated.</p>
                <div className='footer-right'>
                    <button className='ffam-lato tc-white fw-bold fs-s'>Terms & Conditions</button>
                    <button className='ffam-lato tc-white fw-bold fs-s'>IPR RIGHTS</button>
                </div>
               
            </div>
        </div>
    )
}

export default Footer