import React, { useState } from 'react';
import './FaqSection.scss'
import Faq from './Faq'

import dropDownImg from '../../../../assets/images/drop-down.png'

function FaqSection() {
    const [faqOpen, setFaqOpen] = useState(false)
    const expandFaq = () => {
        let faq = document.getElementsByClassName('faq-section-inner-main')[0]
        let faqHeight = window.getComputedStyle(document.getElementsByClassName('faq-section-inner')[0]).height

        // let addHeight=window.innerWidth<500?200:30
        faqHeight = `${Number(faqHeight.substring(0, faqHeight.length - 2)) + 30}px`
        console.log(faqHeight)
        if (faqOpen === false) {
            faq.style.height = faqHeight;
            setTimeout(() => {
                faq.style.overflow = 'visible'
                faq.style.height = 'fit-content';
            }, [300])
        }
        else {
            faq.style.height = faqHeight;
            setTimeout(() => {
                faq.style.height = '0px';
            }, [20])
            // setTimeout(()=>{
            faq.style.overflow = 'hidden'
            // },[300])
        }
        setFaqOpen(!faqOpen)
    }

    return (
        <div className='faq-section'>
            <div onClick={() => { expandFaq() }} className='faq-section-title ffam-edo tc-blue fs-xls'>
                FAQ's
                <img src={dropDownImg}
                    style={{ transform: faqOpen === true ? 'rotateZ(-180deg)' : 'rotateZ(0deg)' }}
                />
            </div>
            <div className='faq-section-inner-main'>
                <div className='faq-section-inner'>

                    <Faq
                        question='How do I claim benefits?'
                        answer="You can claim your benefits by filling out this form here:  <linkhere>https://form.jotform.com/230235423176146<<<linkhere>.  Once your information has been submitted, you will be contacted via email with your member registration information and instructions. "
                        link="https://form.jotform.com/230235423176146"
                    />

                    <Faq
                        question='Can I give or donate my benefits to someone else?'
                        answer="Yes!  We believe that people thrive as a PAC!  If you would like to give or donate your benefits to someone in need, simply fill out the claim form with their information or you may select “Pay It Forward” to someone else.  When you donate, everyone wins.  Our “Pay It Forward” program helps artists and community members in Web3 get access to healthcare that they might otherwise not be able to afford, and you get to keep the NFT for other perks, parties, and discounts 😊 "
                    />
                    <Faq
                        question='Who are the wellness benefits provided by?'
                        answer="All benefits are provided through VMD Healthcare Solutions via the Get VMD mobile app.  Founded in 2018, VMD has strategic partnerships with some of the best health and wellness providers in the industry to provide its members with affordable and timely healthcare access.
                        <splithere>
                        On-Demand medical healthcare access is provided via telehealth by Teladoc, the nation’s largest telehealth provider. Mental health services and digital tools are provided by Impact Suite.  Other benefits included are discounts for dental services via Aetna Dental, vision services via Target Optical, Costco, and Visionworks, discounts on prescriptions, CBD products, lab testing and much more.  You can learn more via following us on our social media or by visiting
                        <linkhere>www.getvmd.com<<<linkhere>.
                        "
                        link="https://getvmd.com/"
                    />

                    <Faq
                        question='Can I claim benefits if I purchased an NFT in the marketplace? '
                        answer="No, only one claim per NFT is allowed.  Due to the expenses incurred for providing these benefits, only members who have minted an NFT on our website are eligible to claim benefits.  However, holding a PAC NFT gives you access to purchase the benefits included at a heavily discounted rate of $40 a month.   Members can also continue their benefits once they have expired at this discounted rate as well.  Purchasing a PAC NFT entitles you to significant savings when you consider that the average telehealth call is $75 and the average cost of a therapy session is $150.  "
                    />
                    <Faq
                        question='I have other questions not listed here.  Who can I contact?'
                        answer="Please send any questions via the contact form below and a team member will reply to you as soon as possible. "
                    />
                </div>
            </div>


        </div>
    );
}

export default FaqSection;
