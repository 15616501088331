import React,{useState,useRef} from 'react'
import './WelcomePage.scss'
import Footer from '../../components/footer/Footer'
import welcomeMp4 from '../../assets/videos/welcome.mp4'
import welcomeWebm from '../../assets/videos/welcome.webm'


import { useNavigate } from 'react-router-dom'

import tiktokImg from '../../assets/images/socials/tiktok.svg'
import instagramImg from '../../assets/images/socials/instagram.svg'
import twitterImg from '../../assets/images/socials/twitter.svg'

// import logo from '../../assets/images/transparent-logo.png'

import playingImg from '../../assets/images/playing.png'
import mutedImg from '../../assets/images/muted.png'

function WelcomePage() {
    const [playing, setPlaying] = useState(false)
    const video = useRef()
    const navigateTo = useNavigate()

    const openLink = (link) => {
        window.open(link)
    }
    const setAudio = () =>{
        if(!playing){
            video.current.muted=false
        }
        else{
            video.current.muted=true
        }
        setPlaying(!playing)
    }
    return (
        <div className='welcome-page'>
            <div className='welcome-page-inner'>
                <div className='welcome-page-img-wrapper'>

                    {/* <img className='welcome-logo' src={logo}/> */}
                    <video ref={video} loop muted playsInline autoPlay className='welcome-img'>
                        <source src={welcomeWebm}></source>
                        <source src={welcomeMp4}></source>
                    </video>

                    <div onClick={() => { setAudio() }} className='top-section-audio'>
                        {
                            playing === true ?
                                <img id='top-section-playing' src={playingImg} />
                                : playing === false ?
                                    <img id='top-section-playing' src={mutedImg} />
                                    : null
                        }

                    </div>

                    <div className='welcome-page-enter-wrapper'>
                        {/* <video autoPlay muted loop playsInline className='welcome-page-border'>
                            <source src={videoBorderWebm}/>
                            <source src={videoBorder}/>
                        </video> */}
                        <div className='welcome-page-enter-wrapper-background'>

                        </div>
                        <p className='  tc-white ffam-edo'>
                            WELCOME TO
                            <br />
                            PARTY APES CLUB
                        </p>
                        <div className='welcome-social-wrapper'>
                            <div className='welcome-social-wrapper-border' />

                            {/* <button onClick={() => openLink('https://www.instagram.com/partyapesclub/')} className='welcome-social-btn'>
                                <img src={instagramImg} />
                            </button>
                            <button onClick={() => openLink('https://twitter.com/PartyApesClub')} className='welcome-social-btn' >
                                <img src={twitterImg} />
                            </button>
                            <button onClick={() => openLink('https://www.tiktok.com/@partyapesclub')} className='welcome-social-btn' >
                                <img src={tiktokImg} />
                            </button> */}
                            <div className='welcome-social-wrapper-border' />
                        </div>
                        <button onClick={() => { navigateTo('/home') }} className='welcome-btn fs-s ffam-lato fw-bold'>ENTER</button>
                    </div>
                </div>

            </div>
            {/* <video  autoPlay muted loop playsInline src={bottomStrip} className='welcome-page-bottom-animation'>

            </video> */}

        </div>
    )
}

export default WelcomePage