import './Home.scss';
import { useState, useEffect } from 'react';

import "react-toastify/dist/ReactToastify.css";

import TopSection from './sections/top-section/TopSection'
import TeamSection from './sections/team-section/TeamSection'
import FaqSection from './sections/faq-section/FaqSection'
import ContactUs from './sections/contact-us-section/ContactUs'
import Footer from '../../components/footer/Footer'
import GenesisSection from './sections/genesis-section/Genesis'

// import SucceedSection from './sections/succeed-section/Succeed'
import PartnerLogoSection from './sections/partner-logo-section/PartnerLogo'

import Roadmap from './sections/roadmap/Roadmap'
import Header from '../../components/header/Header'
import WalletModal from '../../components/wallet-modal/WalletModal';

window.__forceSmoothScrollPolyfill__ = true;

// 0x12B282408F524F0eD546227b57038F8B0430439f

function Home() {
    const [walletOpen, setWalletOpen] = useState(false);

    useEffect(() => {
        window.onbeforeunload = function () {
            window.scroll({ top: 0, left: 0 });
        }
    }, [])
    const openLink = (link) => {
        window.open(link)
    }
    return (
        <div className="App">
            <WalletModal open={walletOpen} setOpen={setWalletOpen}></WalletModal>

            <div className='app-bg'></div>

            <Header />
            <TopSection setWalletOpen={setWalletOpen} />
            <GenesisSection />
            <Roadmap />
          
            <TeamSection />
            <PartnerLogoSection />
            <div className='verified-contract-address'>
                <p className='ffam-edo tc-white fs-l'>
                    VERIFIED SMART CONTRACT ADDRESS:
                </p>
                <button onClick={() => { openLink('https://etherscan.io/address/0x00fad1DA8E2DCa3c9e62853F3814e7F822FA9544') }} className='ffam-edo tc-blue fs-l'>
                 0x00fad1DA8E2DCa3c9e62853F3814e7F822FA9544
                </button>
            </div>
            <FaqSection />
           
            <ContactUs />
            <Footer />
        </div>
    );
}

export default Home;
