import React, { useEffect, useRef, useState } from 'react';
import './FaqSection.scss'
import minusImg from '../../../../assets/images/minus.png'
import plusImg from '../../../../assets/images/plus.png'

function Faq({answer,question,link}) {

    const faq=useRef()
    const faqAnswer=useRef()
    const [faqOpen,setFaqOpen]=useState(false)

    const[faqAnswerHeight,setFaqAnswerHeight]=useState(0)
    const[faqHeight,setFaqHeight]=useState(0)


    useEffect(()=>{
        setTimeout(()=>{
            if(faqAnswer==undefined||faq===undefined)return;
            // console.log(faqAnswer.current.offsetHeight)
            setFaqAnswerHeight(faqAnswer.current.offsetHeight)
            // setFaqHeight(faq.current.offsetHeight)
            window.addEventListener('resize',()=>{
                if(faq?.current===null)return
                faq.current.style.height=`85px`;
                setFaqAnswerHeight(faqAnswer.current.offsetHeight)
                // setFaqHeight(faq.current.offsetHeight)
                setFaqOpen(false)
            })
        },[90])
      
    },[faqAnswer,faq])

    const expand=()=>{

        if(!faqOpen){
            faq.current.style.height=`${85+faqAnswerHeight+20}px`;
            // faqAnswer.current.style.height=`${faqAnswerInner.current.offsetHeight}px`;
        }
        else{
            // faqQuestion.current.style.borderBottom='1px solid white';
            // faqAnswer.current.style.height=`0px`;
            faq.current.style.height=`85px`
        }
        
        setFaqOpen(!faqOpen)
    }
    // console.log(answer.split('\n'))
    const openLink=(link)=>{
        window.open(link)
    }
    return (

    <div ref={faq}  className='faq text-white'>
        <div onClick={()=>{expand()}} className='faq-question'>
            <div className='faq-question-title ffam-edo tc-white fw-medium fs-m'>
                {question}
            </div>
            <button className='faq-expand-btn'>
                {
                    faqOpen===false?
                    <img src={plusImg}/>
                    :                    
                    <img  src={minusImg}/>
                }
            </button>
        </div>
        <div  ref={faqAnswer} className='faq-answer fs-xs'>
            <div className='faq-answer-inner'>
                <div className='ffam-lato tc-white fw-light'>
                    {answer.split('<linkhere>').map((ans,i)=>{
                        if(ans.includes('<<')){
                            return(
                                // <a key={i} href={link}>{ans.substr(0,ans.length-2)}</a>
                                <button onClick={()=>{openLink(link)}} key={i}>{ans.substr(0,ans.length-2)}</button>

                            )
                        }
                        else{
                            return ans.split('<splithere>').map((split,i)=>{
                                // console.log(split)
                                if(ans.split('<splithere>').length>1&&i!==ans.split('<splithere>').length-1){
                                    return(
                                        <span  key={i}>

                                            <span >{split}</span>
                                            <br/>
                                            <br/>
                                        </span>
                                    )
                                }
                                else{
                                    return(
                                        <span  key={i}>

                                            <span >{split}</span>
                                        
                                        </span>
                                    ) 
                                }
                            })
                        }
                    })}
                </div>
            </div>
        </div>
    </div>


    );
}

export default Faq;
