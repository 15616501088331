import {useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "../../redux/blockchain/blockchainActions";
import { walletConnect,connectMetamask,connectCoinbase } from "../../redux/blockchain/blockchainActions";

import { fetchData } from "../../redux/data/dataActions";
import { toast } from 'react-toastify';
import './WalletModal.scss'

import metamaskIcn from '../../assets/images/wallet/metamask.webp'
import coinbaseIcn from '../../assets/images/wallet/coinbase.webp'
import walletConnectIcn from '../../assets/images/wallet/walletconnect.webp'



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  
  borderRadius:25,
  boxShadow: 24,
  outline:'none'
};

export default function WalletModal({open,setOpen}) {
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);


  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();

  }, [blockchain.account]);

  const connectMetamaskWallet=()=>{
    dispatch(connectMetamask());
    getData();
    handleClose();
  }
  const connectCoinbaseWallet=()=>{
    dispatch(connectCoinbase());
    getData();
    handleClose();
  }

  const connectWalletConnect=()=>{
    dispatch(walletConnect());
    getData();
    handleClose();
  };
  
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box  sx={style} >
            <div className='wallet-modal'>
                <button onClick={()=>{connectMetamaskWallet()}} className='modal-connect-button tc-white fs-xs fw-bold ffam-anton'>
                    <img src={metamaskIcn}/>
                    Metamask
                 
                </button>
                <button onClick={()=>{connectCoinbaseWallet()}} className='modal-connect-button tc-white fs-xs fw-bold ffam-anton'>
                    <img src={coinbaseIcn}/>
                    Coinbase Wallet
                </button>
                <button style={{borderBottom:'none'}} onClick={()=>{connectWalletConnect()}} className='modal-connect-button tc-white fs-xs fw-bold ffam-anton' >
                    <img src={walletConnectIcn}/>
                    Wallet Connect
                </button>
            </div>
        </Box>
      </Modal>
    </div>
  );
}