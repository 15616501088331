import React, { useState, useEffect } from 'react'
import './InteractiveImg.scss'
import img1 from '../../../../../assets/images/top-section/main.webp'

function TopSection() {

    const interactive = () => {
        let constrain = 300;
        let mouseOverContainer = document.getElementById("ex1");
        let ex1Layer = document.getElementById("ex1-layer");

        function transforms(x, y, el) {
            let box = el.getBoundingClientRect();
            let calcX = -(y - box.y - (box.height / 2)) / constrain;
            let calcY = (x - box.x - (box.width / 2)) / constrain;

            return "perspective(100px) "
                + "   rotateX(" + calcX + "deg) "
                + "   rotateY(" + calcY + "deg) ";
        };

        function transformElement(el, xyEl) {
            el.style.transform = transforms.apply(null, xyEl);
        }

        mouseOverContainer.onmousemove = function (e) {
            let xy = [e.clientX, e.clientY];
            let position = xy.concat([ex1Layer]);
            // console.log(position)

            window.requestAnimationFrame(function () {
                transformElement(ex1Layer, position);
            });
        };
        mouseOverContainer.onmouseleave = function (e) {
            let xy = [0, 0];
            let position = xy.concat([ex1Layer]);

            window.requestAnimationFrame(function () {
                // transformElement(ex1Layer, position);
                ex1Layer.style.transition = 'all 200ms ease-in'
                ex1Layer.style.transform = "perspective(100px) "
                    + "   rotateX(" + 0 + "deg) "
                    + "   rotateY(" + 0 + "deg) "
                setTimeout(() => {
                    ex1Layer.style.transition = 'all 50ms ease-in'
                }, [210])

            });
        };
    }
    useEffect(() => {
        interactive()
    }, [])

    return (
        <div >
            <div id="ex1" className="container">
                <div id="ex1-layer" className="box">
                    <img src={img1} />
                </div>
            </div>
        </div>
    )
}

export default TopSection