import React, { useEffect, useState } from 'react'
import './Header.scss'

import headerLogo from '../../assets/images/logos/long-logo.png'
import headerLogoMain from '../../assets/images/logos/logo.png'

import twitterWhite from '../../assets/images/socials/twitter.svg'
import instagramWhite from '../../assets/images/socials/instagram.svg'
import tiktokLogo from '../../assets/images/socials/tiktok.svg'
import discord from '../../assets/images/socials/discord.svg'
import linktree from '../../assets/images/socials/linktree.svg'


function Header() {
    const [headerMobileOpen, setHeaderMobileOpen] = useState(false)

    const openLink = (link) => {
        window.open(link)
    }

    const openNavBar = () => {
        let header = document.getElementsByClassName('header')[0]

        let hamTop = document.getElementsByClassName('hamburger-top')[0]
        let hamBottom = document.getElementsByClassName('hamburger-bottom')[0]
        let mobileNav = document.getElementsByClassName('header-mobile-nav')[0]

        if (!headerMobileOpen) {
            hamTop.style.transition = 'all 250ms ease-in-out'
            hamBottom.style.transition = 'all 250ms ease-in-out'
            hamTop.style.top = '19px'
            hamTop.style.transform = 'rotate(45deg)'
            hamBottom.style.bottom = '19px'
            hamBottom.style.transform = 'rotate(-45deg)'

            mobileNav.style.display = 'block'
            setTimeout(() => {
                mobileNav.style.opacity = '1'
            }, [15])
            // header.style.backdropFilter='blur(25px)'
        }
        else {
            hamTop.style.transition = 'transform 100ms ease,bottom 300ms ease-in-out,top 300ms ease-in-out'
            hamBottom.style.transition = 'transform 100ms ease,bottom 300ms ease-in-out,top 300ms ease-in-out'
            hamTop.style.top = '14px'
            hamTop.style.transform = 'rotate(0deg)'
            hamBottom.style.bottom = '14px'
            hamBottom.style.transform = 'rotate(0deg)'

            mobileNav.style.opacity = '0'
            setTimeout(() => {
                mobileNav.style.display = 'none'
            }, [250])
            // header.style.backdropFilter='blur(3px)'

        }
        setHeaderMobileOpen(!headerMobileOpen)
    }


    const navigateTo = (location) => {

        if (window.innerWidth < 1220) {
            openNavBar()
        }

        var height = 90

        
        var container = window
        var offsetTop;

        switch (location) {
            case "about":
                container.scroll({ top: 0, left: 0, behavior: "smooth" });
                break;
            case "genesis":
                offsetTop = document.getElementsByClassName("genesis-section")[0].offsetTop - height;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            case "utility":
                offsetTop = document.getElementsByClassName("roadmap")[0].offsetTop - height;
                console.log(offsetTop)
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            case "roadmap":
                let additional=document.getElementsByClassName("genesis-bottom")[0].offsetHeight;
                offsetTop = document.getElementsByClassName("roadmap")[0].offsetTop + additional +150;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            case "team":
                offsetTop = document.getElementsByClassName("team-section")[0].offsetTop - height;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            case "faq":
                offsetTop = document.getElementsByClassName("faq-section")[0].offsetTop - height;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
            case "perk-shop":
                openLink('https://perk.shop/pac')
                break;
            case "contact":
                offsetTop = document.getElementsByClassName("contact-section")[0].offsetTop - height;
                container.scroll({ top: offsetTop, left: 0, behavior: "smooth" });
                break;
        
            default:
                break;
        }

    }





    const [width, setWidth] = useState(0)

    // useEffect(() => {
    //     window.addEventListener("resize", () => {
    //         setWidth(window.innerWidth)
    //     })
    // }, [])


    return (
        <div className='header '>
            {/* <span className='tc-white'>
                {width}
            </span> */}


            <div className='header-inner'>
                
                <div className='header-left'>
                 <img src={headerLogoMain} className='header-logo' />
                </div>
                <div className='header-right ffam-light'>
                    <div className='header-links'>
                        {/* <button onClick={()=>navigateTo('mint')} className='header-link tc-white fs-xxs ffam-lato'>MINT</button> */}
                        <button onClick={()=>navigateTo('about')} className='header-link tc-white fs-xxs ffam-lato fw-bold'>ABOUT</button>
                        {/* <button onClick={()=>navigateTo('genesis')} className='header-link tc-white fs-xxs ffam-lato fw-bold'>GENESIS</button> */}
                        <button onClick={()=>navigateTo('utility')} className='header-link tc-white fs-xxs ffam-lato fw-bold'>UTILITY</button>

                        <button onClick={()=>navigateTo('roadmap')} className='header-link tc-white fs-xxs ffam-lato fw-bold'>ROADMAP</button>
                        <button onClick={()=>navigateTo('team')} className='header-link tc-white fs-xxs ffam-lato fw-bold'>TEAM</button>
                        <button onClick={()=>navigateTo('faq')} className='header-link tc-white fs-xxs ffam-lato fw-bold'>FAQ's</button>
                        {/* <button onClick={()=>navigateTo('perk-shop')} className='header-link tc-white fs-xxs ffam-lato fw-bold'>PERK SHOP</button> */}
                        <button onClick={()=>openLink('https://form.jotform.com/230235423176146')} className='header-link tc-white fs-xxs ffam-lato fw-bold'>CLAIM BENEFITS</button>

                        <button onClick={()=>navigateTo('contact')} className='header-link tc-white fs-xxs ffam-lato fw-bold'>CONTACT US</button>
                        <div className='header-social-wrapper'>
                            <button onClick={()=>{openLink('https://twitter.com/PartyApesClub')}} className='header-social-btn' >
                                <img src={twitterWhite}/>
                            </button>
                            <button onClick={()=>{openLink('https://www.instagram.com/partyapesclub/')}} className='header-social-btn'>
                                <img src={instagramWhite}/>
                            </button>
                            {/* <button onClick={()=>{openLink('https://discord.com/invite/zx5hzYf86c/')}} className='header-social-btn'>
                                <img src={discord}/>
                            </button> */}
                            <button onClick={()=>{openLink('https://www.tiktok.com/@partyapesclub')}} className='header-social-btn'>
                                <img id='header-linkedin' src={tiktokLogo}/>
                            </button>
                            <button onClick={()=>{openLink('https://linktr.ee/partyapesclub')}} className='header-social-btn'>
                                <img id='header-linktree' src={linktree}/>
                            </button>
                        </div>
                    
                    </div>
                    <button  onClick={()=>{openNavBar()}} className='header-hamburger'>
                        <div className='header-hamburger-inner'>
                            <div className='hamburger-top'></div>
                            <div className='hamburger-bottom'></div>
                        </div>
                    </button>
                </div>


            

                <div className='header-mobile-nav'>
                    <div className='header-mobile-nav-inner '>
                        <img src={headerLogoMain} className='header-mobile-logo' />
                        <button className='fs-s tc-white mobile-nav-button ffam-lato fw-bold' onClick={() => { navigateTo('genesis') }}>GENESIS</button>
                        <button className='fs-s tc-white mobile-nav-button ffam-lato fw-bold' onClick={() => { navigateTo('roadmap') }}>ROADMAP</button>
                        <button className='fs-s tc-white mobile-nav-button ffam-lato fw-bold' onClick={() => { navigateTo('team') }}>TEAM</button>
                        <button className='fs-s tc-white mobile-nav-button ffam-lato fw-bold' onClick={() => { navigateTo('faq') }}>FAQ</button>
                        <button className='fs-s tc-white mobile-nav-button ffam-lato fw-bold' onClick={() => { navigateTo('contact') }}>CONTACT US</button>


                      
                        <button onClick={() => openLink('https://twitter.com/PartyApesClub')} className='fs-s  tc-white mobile-nav-button ffam-lato'>
                            TWITTER
                            <img className='header-twitter-icn' src={twitterWhite}></img>
                        </button>
                        <button onClick={() => openLink('https://www.instagram.com/partyapesclub/')} className='fs-s  tc-white mobile-nav-button ffam-lato'>
                            INSTAGRAM
                            <img className='header-twitter-icn' src={instagramWhite}></img>
                        </button>
                        {/* <button onClick={() => openLink('https://discord.com/invite/zx5hzYf86c')} className='fs-s  tc-white mobile-nav-button ffam-lato'>
                            DISCORD
                            <img className='header-twitter-icn' src={discord}></img>
                        </button> */}
                        <button onClick={() => openLink('https://www.tiktok.com/@partyapesclub')} className='fs-s  tc-white mobile-nav-button ffam-lato'>
                            TIKTOK
                            <img className='header-discord-icn' src={tiktokLogo}></img>
                        </button>
                        <button onClick={() => openLink('https://linktr.ee/partyapesclub')} className='fs-s  tc-white mobile-nav-button ffam-lato'>
                            LINKTREE
                            <img className='header-discord-icn' src={linktree}></img>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
